.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

input, h1, h2, h3, h4, h5, h6, small, a, .alert, button, span {
  font-family: 'Poppins', sans-serif !important;
}

.horizontal-slider {
  width: 100%;
  max-width: 500px;
  height: 2px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 16px;
}

.slider-thumb {
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: white;
  border: 1px solid #3A5579;
  color: white;
  cursor: pointer;
  box-sizing: border-box;
  top: -8px;
}

.slider-thumb.active {
  background-color: #3A5579;
}

.slider-track.slider-track-0 {
  position: relative;
  height: 2px;
  background-color: #3A5579;
}

.slider-track.slider-track-1 {
  height: 2px;
  background-color: #ddd;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
